import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e3efc28e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-title"
};
const _hoisted_2 = {
  class: "dialog-contens"
};
const _hoisted_3 = {
  class: "dialog-btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    modelValue: $data.show,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.show = $event),
    title: $props.title,
    message: $props.message,
    onCancel: $options.onCancel,
    onConfirm: $options.onConfirm,
    class: "my-dialog"
  }, {
    title: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, _toDisplayString($props.title), 1), $props.showCloseIcon ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "close-icon",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.onCancel && $options.onCancel(...args))
    }, [_createVNode(_component_van_icon, {
      name: "cross"
    })])) : _createCommentVNode("", true)])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString($props.message), 1)])]),
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_3, [$props.showCancelButton ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "btn-canel dialog-btn",
      onClick: _cache[1] || (_cache[1] = (...args) => $options.onCancel && $options.onCancel(...args))
    }, " 取消 ")) : _createCommentVNode("", true), $props.showConfirmButton ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass(["btn-enter dialog-btn", {
        loding: $props.btnLogin
      }]),
      onClick: _cache[2] || (_cache[2] = (...args) => $options.onConfirm && $options.onConfirm(...args))
    }, " 确定 ", 2)) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["modelValue", "title", "message", "onCancel", "onConfirm"]);
}